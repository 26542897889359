import React from "react"
import { Link, graphql } from "gatsby"
import BlogNavBar from '../components/BlogNavBar'
import SheetkraftFooter from '../components/SheetkraftFooter.js';
import LetsTalk from '../components/LetsTalk';
import Img from 'gatsby-image'
import '../styles/main.css'
import { Helmet } from 'react-helmet';
import styles from './blogpost.module.css';
import Share from '../components/Share';

import AnchorLink from 'react-anchor-link-smooth-scroll'

function RecentPost({ nodes }) {
  return (
    <ul style={{padding:'0'}}>
      <li style={{listStyleType:'none'}}>
        <Link to={`/blog/${nodes.version}/`} className={styles.textDecoration}>
          <h4 className={styles.frontmatterTitle} style={{paddingTop:'0', paddingLeft:'0', marginBottom:'0'}}>
            Version {nodes.version} has been released
          </h4>
        </Link>
        <p className={styles.frontmatterPublished} style={{padding:'0'}}>
          <span className={styles.frontmatterAuthor} style={{ padding: '0' }}>- SheetKraft Bot </span>
          {/* <span> &bull;</span> {nodes.published} */}

        </p>
      </li>
    </ul>
  );
}

export default class BlogAnnouncement extends React.Component {

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  //for scroll
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    let sk_sticky = document.querySelector('.sk-adaptive-header');
    sk_sticky.classList.add('sk-is-sticky');

    if (document.documentElement.scrollTop === 0) {
      sk_sticky.classList.remove('sk-is-sticky');
    }
  }

  render() {

    const headingStyle = {
      color: '#000',
      fontWeight:'bold'
    }

    const post = this.props;
    const version = post.pageContext.version;
    const pageContent = post.pageContext.pageContent;
    const versionDate = post.pageContext.published;
    const category = post.pageContext.category;
    const tags = post.pageContext.tag;
    // const author = post.pageContext.author;
    const files = post.pageContext.files;
    // const categoryAnnouncement = post.frontmatter.category === 'announcements';

    let totalPosts = post.pageContext.blogAnnouncementPost.sort(function (a, b) {
      return new Date(b.node.published || b.node.frontmatter.published) - new Date(a.node.published || a.node.frontmatter.published);
    });
    let recentPosts = totalPosts.filter(p => p.node.category === category && p.node.version !== version).map(post => post.node).slice(0, 4);

    const gotoContactBlogAnnouncement = {
      position: 'fixed',
      top: '47%',
      left: '0px',
      background: '#4472c4',
      color: '#fff',
      padding: '10px',
      transition: 'linear .2s',
      zIndex: '2',
      cursor: 'pointer'
    }

    return (

      <div>

        <Helmet>
          <meta name="description" property="og:description" content="SheetKraft builds high quality web-based applications using pre-designed powerful building blocks and connects to diverse sources of data across all your internal business applications" />
          <meta name="title" property="og:title" content="SheetKraft" />
          <meta name="image" property="og:image" content="https://www.sheetkraft.com/marketing-assets/images/sk-logo-sticky.png" />
          <meta name="author" content="SheetKraft" />
          <meta name="keywords"
            content=" automation, automation anywhere, uipath, rpa, robotic process automation, automate processes, robotic automation process, rpa services, rpa software, rpa tools, blue prismno code, rapid application development,business process automation" />
          <title>SheetKraft - Blog</title>
        </Helmet>

        <BlogNavBar />

        <div className={styles.wrapper} >

          <div className={styles.textCenter}>
            <h1 className={styles.frontmatterTitle} >Version {version} has been released</h1>

            <p className={styles.colorGrey} >
              Posted under {
                < Link to={`/blog/category/${category}/`
                }
                  className={
                    styles.capitalize
                  } > {
                    category
                  } </Link>
              } on {versionDate} by <span className={styles.red} >SheetKraft Bot</span>
            </p>

          </div>
          <div className="share" style={{textAlign:'center'}}>
              <Share
              socialConfig={{
                config: {
                  url: `${this.props.data.site.siteMetadata.siteUrl}${this.props.pageContext.slug}`,
                  title: version
                },
              }}
            />

          </div>

          {
            !!post.frontmatter && !!post.frontmatter.hero && !!post.frontmatter.hero.childImageSharp ? < Img fluid={
              post.frontmatter.hero.childImageSharp.fluid
            } className={styles.maxHeight} imgStyle={{ objectFit: "none" }} /> : ''
          }

          <br />

          <div >
            <ul>

              {
                pageContent.map((content, id) => (

                  <li key={id} dangerouslySetInnerHTML={{ __html: content }}></li>
                ))
              }
              {
                files && <li style={{ display: 'block' }}>
                  <p>Manual Download links (if script based download fails)</p>
                  <ul>
                    {
                      files.includes('patch') && <li style={{ listStyleType: 'disc' }}>
                        <a download href={`https://releases.sheetkraft.com/update/SheetKraft.Patch.${version}.zip`}>Patch</a>
                      </li>
                    }
                    {
                      files.includes('full') && <li style={{ listStyleType: 'disc' }}>
                        <a download href={`https://releases.sheetkraft.com/update/SheetKraft.Full.${version}.zip`}>Full</a>
                      </li>
                    }
                    {
                      files.includes('setup') && <li style={{ listStyleType: 'disc' }}>
                        <a download href={`https://releases.sheetkraft.com/update/SheetKraftSetup.v.${version}.exe`}>Addin Installer</a>
                      </li>
                    }
                  </ul>

                </li>
              }

            </ul>
          </div>
          <br />

          <hr className={styles.lightGrey} />
          <p className="tagged" >Tagged: <Link key={tags} to={`/blog/tag/${tags}/`} className="tagsList" >{tags}</Link> </p>
          
          <hr className={styles.lightGrey} />

          <div className={styles.similarPosts}>
            <div >
              <h3 className={styles.recentPostsPadding} style={headingStyle}>Recent Posts</h3>
              <hr className={styles.lightGrey} />
            </div>
            <div className={styles.gridsStyles}>
              {recentPosts.map((node, index) => (
                <RecentPost nodes={node} key={index} />
              ))}

            </div>
          </div>
        </div>

        <LetsTalk />
        <SheetkraftFooter />
        <AnchorLink href='#contactForm' className="scrolldown" id="gotoContactBlogAnnouncement" style={gotoContactBlogAnnouncement}> < i className="fa fa-phone"> </i> </AnchorLink>
      </div>

    )
  }
}

export const query = graphql`
 query  {      
    site {
      id
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

